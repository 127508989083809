.loader-text {
  font-size: 24px;
  font-weight: bold;
  color: #aeaeae;
  text-align: center;
  animation: spin 1s infinite linear, scale 1s 1s forwards;
  transition: transform 0.1s ease-in-out;
  animation-duration: 1s, 1s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(7.5);
  }
}

.hidden {
  display: none;
}
